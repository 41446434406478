<template>
  <div class="px-4 mt-24 md:max-w-screen-xl md:m-auto md:mt-48 pb-40">
    <h2 class="text-3xl font-medium">
      Datenschutzerklärung
    </h2>
    <h3 class="text-2xl pt-4 relative group w-fit">
      Einleitung und Überblick
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">
      <span class="mt-2">Wir haben diese Datenschutzerklärung (Fassung 31.08.2023-322573065) verfasst, um
        Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und
        anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz
        Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B.
        Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
        Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
       <b> Kurz gesagt:</b> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.
      </span>
    </div>
    <div class="pl-5 mt-4">
      <span class="mt-2">Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische
        Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so
        einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist,
        werden technische <b>Begriffe leserfreundlich erklärt</b>, Links zu weiterführenden
        Informationen geboten und <b>Grafiken</b> zum Einsatz gebracht. Wir informieren damit in klarer
        und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
        personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage
        gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und
        juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es
        um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und
        informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht
        kannten.
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im
        Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen
        und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie
        selbstverständlich auch im Impressum.
      </span>
    </div>
    <h3 class="text-2xl pt-4 relative group w-fit">
      Anwendungsbereich
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">
      <span class="mt-2">Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten
        personenbezogenen Daten und für alle personenbezogenen Daten, die von uns
        beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten
        meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung
        personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte
        anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser
        Datenschutzerklärung umfasst:
        <ol class="pl-5 list-disc">
          <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
          <li>Social Media Auftritte und E-Mail-Kommunikation</li>
          <li>mobile Apps für Smartphones und andere Geräte</li>
        </ol>
        <b>Kurz gesagt: </b> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
        Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten
        wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
        gegebenenfalls gesondert informieren.
      </span>
    </div>
    <h3 class="text-2xl pt-4 relative group w-fit">
      Rechtsgrundlagen
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">
      <span class="mt-2">In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu
        den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der
        Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu
        verarbeiten.
        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES
        EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
        Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex,
        dem Zugang zum EU-Recht, unter <a class="font-medium" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?
        uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?
        uri=celex%3A32016R0679</a> nachlesen.
      </span>
      <p class="my-5">Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
      <ol class="pl-5 list-decimal">
        <li><b>Einwilligung</b> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
          gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
          Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
        </li>
        <li><b>Vertrag</b> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
          Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum
          Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
          personenbezogene Informationen.
        </li>
        <li><b>Rechtliche Verpflichtung</b> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer
          rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind
          wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
          enthalten in der Regel personenbezogene Daten.
        </li>
        <li><b>Berechtigte Interessen</b> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
          Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
          Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse
          Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben
          zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
        </li>
      </ol>
      <span>
        Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und
        Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns
        in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte,
        wird diese an der entsprechenden Stelle ausgewiesen.
      </span>
      <p class="py-5">
        Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
      </p>
      <ol class="pl-5">
        <li>In <b>Österreich</b> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
          Verarbeitung personenbezogener Daten <b>(Datenschutzgesetz)</b>, kurz <b>DSG</b>.
        </li>
        <li>In <b>Deutschland</b> gilt das <b>Bundesdatenschutzgesetz</b>, kurz <b>BDSG</b>.</li>
      </ol>
      <span>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir
        Sie in den folgenden Abschnitten darüber.</span>
    </div>
    <h3 class="text-2xl pt-4 relative group w-fit">
      Kontaktdaten des Verantwortlichen
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="mt-2 pl-5">
      <span>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten
        haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:</span>
      <p>SCT Events & Marketing</p>
      <p>Lise Meitner Straße 24</p>
      <p>63457 Hanau</p>
    </div>
    <h3 class="text-2xl pt-4 relative group w-fit">
      Speicherdauer
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="mt-2 pl-5">
      <span>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung
        unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles
        Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der
        Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
        gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches
        Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</span>
      <p class="py-5">Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur
        Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine
        Pflicht zur Speicherung besteht, gelöscht.</p>
      <span>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter
        unten, sofern wir weitere Informationen dazu haben.</span>
    </div>
    <h3 class="text-2xl pt-4 relative group">
      Rechte laut Datenschutz-Grundverordnung
      <span class="absolute inset-x-0 bottom-1  h-2 w-40 rounded-lg z-[-1]"/>
    </h3>
    <div class="mt-2 pl-5">
      <span>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen
        zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</span>
      <ol class="pl-5 list-disc">
        <li>
          Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von
          Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der
          Daten zu erhalten und die folgenden Informationen zu erfahren:
          <ol class="pl-5 list-disc">
            <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
            <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
            <li>wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
              werden, wie die Sicherheit garantiert werden kann;
            </li>
            <li>wie lange die Daten gespeichert werden;</li>
            <li>das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem
              Widerspruchsrecht gegen die Verarbeitung;
            </li>
            <li>dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
              diesen Behörden finden Sie weiter unten);
            </li>
            <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
            <li>ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
              werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
            </li>
          </ol>
        </li>
        <li>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
          bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
        </li>
        <li>Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
          Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten
          verlangen dürfen.
        </li>
        <li>
          Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung,
          was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter
          verwenden.
        </li>
        <li>
          Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was
          bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur
          Verfügung stellen.
        </li>
        <li>
          Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
          Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
          <ol class="pl-5 list-disc">
            <li>
              Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches
              Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
              (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung
              Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir
              diesem Widerspruch rechtlich nachkommen können.
            </li>
            <li>
              Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
              jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen
              Ihre Daten danach nicht mehr für Direktmarketing verwenden.
            </li>
            <li>
              Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
              gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten
              danach nicht mehr für Profiling verwenden.
            </li>
          </ol>
        </li>
        <li>
          Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
          ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling)
          beruhenden Entscheidung unterworfen zu werden.
        </li>
        <li>
          Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie
          können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der
          Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen
          die DSGVO verstößt.
        </li>
      </ol>
      <span>
        <b>Kurz gesagt:</b> Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche
        Stelle bei uns zu kontaktieren!
      </span>
      <p class="py-5">
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht
        verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden
        sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die
        Datenschutzbehörde, deren Website Sie unter https://www.dsb.gv.at/ finden. In
        Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere
        Informationen können Sie sich an die Bundesbeauftragte für den Datenschutz und die
        Informationsfreiheit (BfDI) wenden. Für unser Unternehmen ist die folgende lokale
        Datenschutzbehörde zuständig:
      </p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Rheinland-Pfalz Datenschutzbehörde
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Kommunikation
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular
        kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
      <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit
        zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange
        gespeichert bzw. solange es das Gesetz vorschreibt</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Betroffene Personen
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
        Kommunikationswege den Kontakt zu uns suchen.</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Telefon
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim
        eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem
        können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur
        Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der
        Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      E-Mail
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem
        jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur
        Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
        Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Online Formulare
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem
        Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden
        gelöscht, sobald der Geschäftsfall beendet wurde und es
        gesetzliche Vorgaben erlauben.</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Rechtsgrundlagen
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
      <ol class="px-5 list-disc">
        <li>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten
          zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
        </li>
        <li>Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung
          eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem
          Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B.
          die Vorbereitung eines Angebots, verarbeiten;
        </li>
        <li>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und
          geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu
          sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber
          notwendig, um die Kommunikation effizient betreiben
          zu können.
        </li>
      </ol>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Auftragsverarbeitungsvertrag (AVV)
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">In diesem Abschnitt möchten wir Ihnen erklären, was ein Auftragsverarbeitungsvertrag ist
        und warum dieser benötigt wird. Weil das Wort “Auftragsverarbeitungsvertrag” ein
        ziemlicher Zungenbrecher ist, werden wir hier im Text auch öfters nur das Akronym AVV
        benutzen. Wie die meisten Unternehmen arbeiten wir nicht alleine, sondern nehmen auch
        selbst Dienstleistungen anderer Unternehmen oder Einzelpersonen in Anspruch. Durch
        die Einbeziehung verschiedener Unternehmen bzw. Dienstleister kann es sein, dass wir
        personenbezogene Daten zur Verarbeitung weitergeben. Diese Partner fungieren dann als
        Auftragsverarbeiter, mit denen wir einen Vertrag, den sogenannten
        Auftragsverarbeitungsvertrag (AVV), abschließen. Für Sie am wichtigsten zu wissen ist,
        dass die Verarbeitung Ihrer personenbezogenen Daten ausschließlich nach unserer
        Weisung erfolgt und durch den AVV geregelt werden muss.</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Wer sind Auftragsverarbeiter?
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wir sind als Unternehmen und Websiteinhaber für alle Daten, die wir von Ihnen
        verarbeiten verantwortlich. Neben den Verantwortlichen kann es auch sogenannte
        Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen bzw. jede Person, die in
        unserem Auftrag personenbezogene Daten verarbeitet. Genauer und nach der DSGVO-Definition gesagt: jede
        natürliche oder juristische Person, Behörde, Einrichtung oder eine
        andere Stelle, die in unserem Auftrag personenbezogene Daten verarbeitet, gilt als
        Auftragsverarbeiter. Auftragsverarbeiter können folglich Dienstleister wie Hosting- oder
        Cloudanbieter, Bezahlungs- oder Newsletter-Anbieter oder große Unternehmen wie
        beispielsweise Google oder Microsoft sein.</p>
      <p class="py-5">
        Zur besseren Verständlichkeit der Begrifflichkeiten hier ein Überblick über die drei Rollen
        in der DSGVO:
      </p>
      <p><b>Betroffener</b> (Sie als Kunde oder Interessent) <b>→ Verantwortlicher</b> (wir als Unternehmen
        und Auftraggeber) <b>→ Auftragsverarbeiter</b> (Dienstleister wie z. B. Webhoster oder
        Cloudanbieter)</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Inhalt eines Auftragsverarbeitungsvertrages
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="pl-5">

      <p class="py-5">Wie bereits oben erwähnt, haben wir mit unseren Partnern, die als Auftragsverarbeiter
        fungieren, einen AVV abgeschlossen. Darin wird allen voran festgehalten, dass der
        Auftragsverarbeiter die zu bearbeitenden Daten ausschließlich gemäß der DSGVO
        verarbeitet. Der Vertrag muss schriftlich abgeschlossen werden, allerdings gilt in diesem
        Zusammenhang auch der elektronische Vertragsabschluss als „schriftlich“. Erst auf der
        Grundlage des Vertrags erfolgt die Verarbeitung der personenbezogenen Daten. Im
        Vertrag muss folgendes enthalten sein:
      </p>
      <ol class="pl-5 list-disc">
        <li>Bindung an uns als Verantwortlichen</li>
        <li>Pflichten und Rechte des Verantwortlichen</li>
        <li>Kategorien betroffener Personen</li>
        <li>Art der personenbezogenen Daten</li>
        <li>Art und Zweck der Datenverarbeitung</li>
        <li>Gegenstand und Dauer der Datenverarbeitung</li>
        <li>Durchführungsort der Datenverarbeitung</li>
      </ol>
      <p class="py-3">Weiters enthält der Vertrag alle Pflichten des Auftragsverarbeiters. Die wichtigsten
        Pflichten sind:</p>
      <ol class="pl-5 list-disc">
        <li>Maßnahmen zur Datensicherheit zu gewährleisten</li>
        <li>mögliche technische und organisatorischen Maßnahmen zu treffen, um die Rechte
          der betroffenen Person zu schützen
        </li>
        <li>ein Daten-Verarbeitungsverzeichnis zu führen</li>
        <li>auf Anfrage der Datenschutz-Aufsichtsbehörde mit dieser zusammenzuarbeiten</li>
        <li>eine Risikoanalyse in Bezug auf die erhaltenen personenbezogenen Daten
          durchzuführen
        </li>
        <li>Sub-Auftragsverarbeiter dürfen nur mit schriftlicher Genehmigung des
          Verantwortlichen beauftragt werden
        </li>
      </ol>
      <p>Wie so eine AVV konkret aussieht, können Sie sich beispielsweise unter <a
          href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html">https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html</a>
        ansehen. Hier wird ein Mustervertrag vorgestellt</p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Cookies
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="space-y-4 pl-5">
      <h4 class="font-medium text-xl">Was sind Cookies?</h4>
      <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
        Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie
        die folgende Datenschutzerklärung besser verstehen.</p>
      <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte
        Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft
        Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese
        Dateien nennt man Cookies</p>
      <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
        Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch
        noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine
        Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch
        im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers,
        untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition
        eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
      <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder
        persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr
        Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies
        weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind.
        In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise
        Firefox sind alle Cookies in einer einzigen Datei gespeichert</p>
      <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies
        werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google
        Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da
        jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein
        paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
        enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf
        Informationen Ihres PCs zugreifen</p>
      <p>So können zum Beispiel Cookie-Daten aussehen:</p>
      <div>
        <p><b>Name:</b> _ga</p>
        <p><b>Wert:</b> GA1.2.1326744211.152322573065-9</p>
        <p><b>Verwendungszweck:</b> Unterscheidung der Websitebesucher</p>
        <p><b>Ablaufdatum:</b> Nach 2 Jahren</p>
      </div>
      <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
      <ol class="pl-5 list-disc">
        <li>Mindestens 4096 Bytes pro Cookie</li>
        <li>Mindestens 50 Cookies pro Domain</li>
        <li>Mindestens 3000 Cookies insgesamt</li>
      </ol>
      <h4 class="font-medium text-xl">Welche Arten von Cookies gibt es?</h4>
      <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten
        Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An
        dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
      </p>
      <p>
        Man kann 4 Arten von Cookies unterscheiden:
      </p>
      <div>

        <h4 class="font-medium text-xl">Unerlässliche Cookies</h4>
        <p>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
          Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann
          auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird
          der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
        </p>
      </div>
      <div>

        <h4 class="font-medium text-xl">Zweckmäßige Cookies</h4>
        <p>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige
          Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und
          das Verhalten der Website bei verschiedenen Browsern gemessen.
        </p>
      </div>
      <div>

        <h4 class="font-medium text-xl">Zielorientierte Cookies</h4>
        <p>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
          eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
        </p>
      </div>
      <div>

        <h4 class="font-medium text-xl">Werbe-Cookies</h4>
        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser
          Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem
          Cookie gespeichert.
        </p>
        <p class="py-4">
          Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht
          scheuen, empfehlen wir <a href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem Request for
          Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management
          Mechanism”.
        </p>
      </div>
      <h4 class="font-medium text-xl">Zweck der Verarbeitung über Cookies</h4>
      <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie
        weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.
      </p>
      <h4 class="font-medium text-xl">Welche Daten werden verarbeitet?</h4>
      <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in
        Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie
        im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw.
        gespeicherten Daten informieren.
      </p>
      <h4 class="font-medium text-xl">Speicherdauer von Cookies</h4>
      <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert.
        Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere
        Jahre auf einem Computer gespeichert bleiben.
      </p>
      <p>
        Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren
        Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten
        “Widerspruchsrecht”). Ferner werden Cookies, die auf einer Einwilligung beruhen,
        spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der
        Speicherung bis dahin unberührt bleibt.
      </p>
      <h4 class="font-medium text-xl">Widerspruchsrecht – wie kann ich Cookies löschen?</h4>
      <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von
        welchem Service oder welcher Website die Cookies stammen, haben Sie immer die
        Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum
        Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
        zulassen.
      </p>
      <p>
        Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden,
        wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren
        Browser-Einstellungen finden:
      </p>
      <a href="https://support.google.com/chrome/answer/95647?tid=322573065">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
      <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
      <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=322573065">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf ihrem Computer abgelegt haben</a>
      <a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=322573065">Internet Explorer: Löschen und Verwalten von Cookies</a>
      <a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=322573065">Microsoft Edge: Löschen und Verwalten von Cookies</a>
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so
        einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können
        Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die
        Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in
        Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren
        Chrome” im Falle eines Chrome Browsers.
      </p>
      <h4 class="font-medium text-xl">Rechtsgrundlage</h4>
      <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
        Speichern von Cookies eine<b> Einwilligung</b>  (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
        verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche
        Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser
        Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden
        die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die
        Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
      </p>
      <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen
        <b>berechtigte Interessen</b> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen
        wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
        Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
      <p>
        Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im
        Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
      </p>
      <p>
        In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies
        informiert, sofern eingesetzte Software Cookies verwendet.
      </p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Bewerbungsdaten
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="space-y-4 pl-5">
      <h4 class="font-medium text-xl">Was sind Bewerbungsdaten?</h4>
      <p>Sie können sich bei uns per E-Mail, Online-Formular oder über ein Recruiting-Tool für eine
        Arbeitsstelle in unserem Unternehmen bewerben. Alle Daten, die wir im Rahmen einer
        Bewerbung von Ihnen erhalten und verarbeiten, zählen zu den Bewerbungsdaten. Dabei
        geben Sie immer auch personenbezogene Daten wie etwa Namen, Geburtsdatum,
        Adresse und Telefonnummer preis.
      </p>
      <h4 class="font-medium text-xl">Warum verarbeiten wir Bewerbungsdaten?</h4>
      <p>Wir verarbeiten Ihre Daten, damit wir ein ordentliches Auswahlverfahren in Bezug auf die
        ausgeschriebene Stelle betreiben können. Zusätzlich halten wir auch gerne Ihre
        Bewerbungsunterlagen in unserem Bewerbungsarchiv. Denn oft kommt es vor, dass für
        die ausgeschriebene Stellen eine Zusammenarbeit aus den unterschiedlichsten Gründen
        nicht klappt, wir aber von Ihnen und Ihrer Bewerbung beeindruckt sind und uns eine
        zukünftige Zusammenarbeit sehr gut vorstellen können. Sofern Sie uns dafür Ihre
        Einwilligung geben, archivieren wir Ihre Unterlagen, damit wir Sie für zukünftige Aufgaben
        in unserem Unternehmen leicht kontaktieren können.
      </p>
      <p>
        Wir garantieren Ihnen, dass wir besonders behutsam mit Ihren Daten umgehen und immer
        nur innerhalb des rechtlichen Rahmens Ihre Daten verarbeiten. Auch innerhalb unseres
        Unternehmens werden Ihre Daten nur an Personen weitergeleitet, die unmittelbar mit Ihrer
        Bewerbung zu tun haben. Kurz gesagt: Ihre Daten sind bei uns sicher aufgehoben!
      </p>
      <h4 class="font-medium text-xl">Welche Daten werden verarbeitet?</h4>
      <p>Wenn Sie sich zum Beispiel bei uns per E-Mail bewerben, erhalten wir natürlich, wie oben
        erwähnt, auch personenbezogene Daten. Selbst die E-Mail-Adresse zählt schon zu den
        personenbezogenen Daten. Verarbeitet werden im Zuge eines Bewerbungsverfahrens
        allerdings nur jene Daten, die für unsere Entscheidung, ob wir Sie in unserem Team
        begrüßen wollen oder nicht, relevant sind.
      </p>
      <p>
        Welche Daten genau verarbeitet werden, hängt in erster Linie von der
        Stellenausschreibung ab. Meistens handelt es sich aber um Namen, Geburtsdatum,
        Kontaktdaten und Qualifikationsnachweise. Wenn Sie die Bewerbung über ein Online-Formular einreichen, werden die Daten verschlüsselt an uns weitergegeben. Schicken Sie
        uns die Bewerbung per E-Mail, findet diese Verschlüsselung nicht statt. Für den Weg der
        Übertragung können wir somit keine Verantwortung übernehmen. Sobald die Daten aber
        auf unseren Servern sind, sind wir für die rechtmäßige Handhabung Ihrer Daten
        verantwortlich.
      </p>
      <p>
        Während eines Bewerbungsvorgangs können neben den oben genannten Daten auch
        Informationen zu Ihrer Gesundheit oder Ihrer ethnischen Herkunft angefragt werden, damit
        wir und Sie die Rechte in Bezug auf Arbeitsrecht, sozialer Sicherheit und Sozialschutz
        ausüben können und gleichzeitig den dazu entsprechenden Pflichten nachkommen
        können. Bei diesen Daten handelt es sich um Daten besonderer Kategorien.
      </p>
      <p>
        Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:
      </p>
      <ol class="pl-5 list-disc">
        <li>Name</li>
        <li>Kontaktadresse</li>
        <li>E-Mail-Adresse</li>
        <li>Telefonnummer</li>
        <li>Geburtsdatum</li>
        <li>Informationen, die aus Anschreiben und Lebenslauf hervorgehen</li>
        <li>Qualifikationsnachweise (z. B.) Zeugnisse</li>
        <li>Daten besonderer Kategorien (z. B. ethnische Herkunft, Gesundheitsdaten,
          religiöse Überzeugungen)</li>
        <li>Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
        <li>Metadaten (IP-Adresse, Geräte-Informationen)</li>
      </ol>
      <h4 class="font-medium text-xl">Wie lange werden die Daten gespeichert?</h4>
      <p>Wenn wir Sie als Teammitglied in unserem Unternehmen aufnehmen, werden Ihre Daten
        für den Zweck des Arbeitsverhältnisses weiterverarbeitet und mindestens bis zur
        Beendigung des Arbeitsverhältnisses bei uns aufbewahrt. Alle Bewerbungsunterlagen
        kommen dann in Ihre Mitarbeiterakte.
      </p>
      <p>
        Bieten wir Ihnen die Arbeitsstelle nicht an, lehnen Sie unser Angebot ab oder ziehen Ihre
        Bewerbung zurück, können wir aufgrund des berechtigten Interesses (Art. 6 Abs. 1 lit. f
        DSGVO) Ihre Daten bis zu 6 Monate nach Abschluss des Bewerbungsverfahrens
        aufbewahren. Danach werden sowohl Ihre elektronischen Daten als auch alle Daten aus
        physischen Bewerbungsunterlagen vollständig gelöscht bzw. vernichtet. Wir behalten uns
        Ihre Daten etwa, damit wir noch etwaige Nachfragen beantworten können oder, damit wir
        im Falle eines Rechtsstreits Nachweise über die Bewerbung vorlegen können. Falls sich
        ein Rechtsstreit anbahnt und wir eventuell die Daten nach Ablauf der 6 Monate immer
        noch benötigen, werden wir die Daten erst dann löschen, wenn es keinen Grund mehr zur
        Aufbewahrung gibt. Sofern es gesetzliche Aufbewahrungspflichten zu erfüllen gibt,
        müssen wir die Daten grundsätzlich länger als 6 Monate speichern.
      </p>
      <p>
        Weiters können wir Ihre Daten auch länger aufbewahren, wenn Sie dafür eine spezielle
        Einwilligung erteilt haben. Das machen wir zum Beispiel, wenn wir uns in Zukunft eine
        Zusammenarbeit mit Ihnen gut vorstellen können. Dann ist es hilfreich Ihre Daten
        archiviert zu haben, um Sie problemlos erreichen können. In diesem Fall kommen die
        Daten in unser Bewerberpool. Selbstverständlich können Sie Ihre Einwilligung zur
        längeren Aufbewahrung Ihrer Daten jederzeit widerrufen. Erfolgt kein Widerruf und geben
        Sie keine neue Einwilligung ab, werden Ihre Daten spätestens nach 2 Jahren gelöscht.
      </p>
      <h4 class="font-medium text-xl">Rechtsgrundlage</h4>
      <p>Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO
        (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6
        Abs. 1 lit. f DSGVO (berechtigte Interessen) und Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung
        besonderer Kategorien).
      </p>
      <p>
        Nehmen wir Sie in unser Bewerbertool auf, passiert dies auf der Grundlage Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Wir weisen Sie darauf hin, dass Ihre Zustimmung
        in unser Bewerbungspool freiwillig ist, keinen Einfluss auf den Bewerbungsprozess hat
        und Sie jederzeit die Möglichkeit haben, Ihre Einwilligung zu widerrufen. Die
        Rechtmäßigkeit der Verarbeitung bis zum Zeitpunkt des Widerrufs bleibt davon unberührt.
      </p>
      <p>
        Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung
        gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der
        Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im
        Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im
        Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten
        gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien
        mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
      </p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Kundendaten
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="space-y-4 pl-5">
      <h4 class="font-medium text-xl">Was sind Kundendaten?</h4>
      <p>Damit wir unser Service bzw. unsere vertraglichen Leistungen anbieten können,
        verarbeiten wir auch Daten unserer Kunden und Geschäftspartner. Unter diesen Daten
        befinden sich immer auch personenbezogene Daten. Unter Kundendaten versteht man
        alle Informationen, die auf Basis einer vertraglichen oder vorvertraglichen
        Zusammenarbeit verarbeitet werden, um die angebotenen Leistungen erbringen zu
        können. Kundendaten sind also alle gesammelten Informationen, die wir über unsere
        Kunden erheben und verarbeiten.
      </p>
      <h4 class="font-medium text-xl">Warum verarbeiten wir Kundendaten?</h4>
      <p>Es gibt viele Gründe, warum wir Kundendaten sammeln und verarbeiten. Der wichtigste
        ist, dass wir zur Bereitstellung unserer Services einfach verschiedene Daten benötigen.
        Manchmal reicht hier schon Ihre E-Mail-Adresse, doch wenn Sie etwa ein Produkt oder
        eine Dienstleistung erwerben, benötigen wir auch Daten wie beispielsweise Name,
        Adresse, Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge auch für
        Marketing- und Vertriebsoptimierungen, damit wir insgesamt unser Service für unsere
        Kunden verbessern können. Ein weiterer wichtiger Punkt ist auch unser Kundenservice,
        der uns stets sehr am Herzen liegt. Wir wollen, dass Sie mit Frage zu unseren Angeboten
        jederzeit zu uns kommen können und dafür brauchen wir zumindest Ihre E-Mail-Adresse.
      </p>
      <h4 class="font-medium text-xl">Welche Daten werden verarbeitet?</h4>
      <p>Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand Kategorien
        wiedergegeben werden. Dies hängt nämlich stets davon ab, welche Leistungen Sie von
        uns beziehen. In manchen Fällen geben Sie uns lediglich Ihre E-Mail-Adresse, damit wir
        beispielsweise mit Ihnen in Kontakt treten können oder wir Ihre Fragen beantworten
        können. In anderen Fällen erwerben Sie ein Produkt oder eine Dienstleistung bei uns und
        dafür benötigen wir deutlich mehr Informationen, wie etwa Ihre Kontaktdaten,
        Zahlungsdaten und Vertragsdaten.
      </p>
      <p>
        Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:
      </p>
      <ol class="pl-5 list-disc">
        <li>
          Name
        </li>
        <li>Kontaktadresse</li>
        <li>E-Mail-Adresse</li>
        <li>Telefonnummer</li>
        <li>Geburtsdatum</li>
        <li>Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
        <li>Vertragsdaten ( Laufzeit, Inhalt)</li>
        <li>Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
        <li>Metadaten (IP-Adresse, Geräte-Informationen)</li>
      </ol>
      <h4 class="font-medium text-xl">Wie lange werden die Daten gespeichert?</h4>
      <p>Sobald wir die Kundendaten zur Erfüllung unserer vertraglichen Pflichten und unserer
        Zwecke nicht mehr benötigen und die Daten auch nicht für mögliche Gewährleistungs- und
        Haftungspflichten nötig sind, löschen wir die entsprechenden Kundendaten. Das ist zum
        Beispiel der Fall, wenn ein geschäftlicher Vertrag endet. Danach beträgt die
        Verjährungsfrist in der Regel 3 Jahre, wobei längere Fristen im Einzelfall möglich sind. Wir
        halten uns natürlich auch an die gesetzlichen Aufbewahrungspflichten. Ihre Kundendaten
        werden ganz sicher nicht an Dritte weitergegeben, wenn Sie dazu nicht explizit eine
        Einwilligung erteilt haben.
      </p>
      <h4 class="font-medium text-xl">Rechtsgrundlage</h4>
      <p>Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO
        (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6
        Abs. 1 lit. f DSGVO (berechtigte Interessen) und in speziellen Fällen (z. B. bei
        medizinischen Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer
        Kategorien).
      </p>
      <p>
        Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung
        gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der
        Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im
        Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im
        Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten
        gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien
        mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.
      </p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Registrierung
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="space-y-4 pl-5">
      <p>Wenn Sie sich bei uns registrieren, kann es zur Verarbeitung personenbezogener Daten
        kommen, sofern Sie Daten mit Personenbezug eingeben bzw. Daten wie die IP-Adresse
        im Zuge der Verarbeitung erfasst werden. Was wir mit dem doch recht sperrigen Begriff
        “personenbezogene Daten” meinen, können Sie weiter unten nachlesen.
      </p>
      <p>
        Bitte geben Sie nur solche Daten ein, die wir für die Registrierung benötigen und für die
        Sie die Freigabe eines Dritten haben, falls Sie die Registrierung im Namen eines Dritten
        durchführen. Verwenden Sie nach Möglichkeit ein sicheres Passwort, welches Sie sonst
        nirgends verwenden und eine E-Mail-Adresse, die Sie regelmäßig abrufen.
      </p>
      <p>
        Im Folgenden informieren wir Sie über die genaue Art der Datenverarbeitung, denn Sie
        sollen sich bei uns wohl fühlen!
      </p>
      <h4 class="font-medium text-xl">Was ist eine Registrierung?</h4>
      <p>Bei einer Registrierung nehmen wir bestimmte Daten von Ihnen entgegen und
        ermöglichen es Ihnen sich später bei uns einfach online anzumelden und Ihr Konto bei uns
        zu verwenden. Ein Konto bei uns hat den Vorteil, dass Sie nicht jedes Mal alles erneut
        eingeben müssen. Spart Zeit, Mühe und verhindert letztendlich Fehler bei der Erbringung
        unserer Dienstleistungen.
      </p>
      <h4 class="font-medium text-xl">Warum verarbeiten wir personenbezogene Daten?</h4>
      <p>Kurz gesagt verarbeiten wir personenbezogene Daten, um die Erstellung und Nutzung
        eines Kontos bei uns zu ermöglichen.
        Würden wir das nicht tun, müssten Sie jedes Mal alle Daten eingeben, auf eine Freigabe
        von uns warten und alles noch einmal eingeben. Das fänden wir und viele, viele Kunden
        nicht so gut. Wie würden Sie das finden?
      </p>
      <h4 class="font-medium text-xl">Welche Daten werden verarbeitet?</h4>
      <p>Alle Daten, die Sie im Zuge der Registrierung angegeben haben, bei der Anmeldung
        eingeben oder im Rahmen der Verwaltung Ihrer Daten im Konto eingeben.
      </p>
      <p>Bei der Registrierung verarbeiten wir folgende Arten von Daten:</p>
      <ol class="pl-5 list-disc">
        <li>Vorname</li>
        <li>Nachname</li>
        <li>E-Mail-Adresse</li>
        <li>Firmenname</li>
        <li>Straße + Hausnummer</li>
        <li>Wohnort</li>
        <li>Postleitzahl</li>
        <li>Land</li>
      </ol>
      <p>
        Bei der Anmeldung verarbeiten wir die Daten, die Sie bei der Anmeldung eingeben wie
        zum Beispiel Benutzername und Passwort und im Hintergrund erfasste Daten wie
        Geräteinformationen und IP-Adressen.
      </p>
      <p>
        Bei der Kontonutzung verarbeiten wir Daten, die Sie während der Kontonutzung eingeben
        und welche im Rahmen der Nutzung unserer Dienstleistungen erstellt werden.
      </p>
      <h4 class="font-medium text-xl">Speicherdauer</h4>
      <p>Wir speichern die eingegebenen Daten zumindest für die Zeit, solange das mit den Daten
        verknüpfte Konto bei uns besteht und verwendet wird, solange vertragliche
        Verpflichtungen zwischen uns bestehen und, wenn der Vertrag endet, bis die jeweiligen
        Ansprüche daraus verjährt sind. Darüber hinaus speichern wir Ihre Daten solange und
        soweit wir gesetzlichen Verpflichtungen zur Speicherung unterliegen. Danach bewahren
        wir zum Vertrag gehörige Buchungsbelege (Rechnungen, Vertragsurkunden,
        Kontoauszüge u.a.) sowie sonstige relevante Geschäftsunterlagen für die gesetzlich
        vorgeschriebene Dauer (i.d.R. einige Jahre) auf.
      </p>
      <h4 class="font-medium text-xl">Widerspruchsrecht</h4>
      <p>Sie haben sich registriert, Daten eingegeben und möchten die Verarbeitung widerrufen?
        Kein Problem. Wie Sie oben lesen können, bestehen die Rechte laut Datenschutz-Grundverordnung auch bei und nach der Registrierung, Anmeldung oder dem Konto bei
        uns. Kontaktieren Sie den weiter oben stehenden Verantwortlichen für Datenschutz, um
        Ihre Rechte wahrzunehmen. Sollten Sie bereits ein Konto bei uns haben, können Sie Ihre
        Daten und Texte ganz einfach im Konto einsehen bzw. verwalten.
      </p>
      <h4 class="font-medium text-xl">Rechtsgrundlage</h4>
      <p>Mit Durchführung des Registrierungsvorgangs treten Sie vorvertraglich an uns heran, um
        einen Nutzungsvertrag über unsere Plattform zu schließen (wenn auch nicht automatisch
        eine Zahlungspflicht entsteht). Sie investieren Zeit, um Daten einzugeben und sich zu
        registrieren und wir bieten Ihnen unsere Dienstleistungen nach Anmeldung in unserem
        System und die Einsicht in Ihr Kundenkonto. Außerdem kommen wir unseren vertraglichen
        Verpflichtungen nach. Schließlich müssen wir registrierte Nutzer bei wichtigen Änderungen
        per E-Mail am Laufenden halten. Damit trifft Art. 6 Abs. 1 lit. b DSGVO (Durchführung
        vorvertraglicher Maßnahmen, Erfüllung eines Vertrags) zu.
      </p>
      <p>
        Gegebenenfalls holen darüber hinaus auch Ihre Einwilligung ein, z.B. wenn Sie freiwillig
        mehr als die unbedingt notwendigen Daten angeben oder wir Ihnen Werbung senden
        dürfen. Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) trifft somit zu.
      </p>
      <p>
        Wir haben außerdem ein berechtigtes Interesse, zu wissen, mit wem wir es zu tun haben,
        um in bestimmten Fällen in Kontakt zu treten. Außerdem müssen wir wissen wer unsere
        Dienstleistungen in Anspruch nimmt und ob sie so verwendet werden, wie es unsere
        Nutzungsbedingungen vorgeben, es trifft also Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
        Interessen) zu.
      </p>
      <p>Hinweis: folgende Abschnitte sind von Usern (je nach Bedarf) anzuhaken:</p>
      <h4 class="font-medium text-xl">Registrierung mit Klarnamen</h4>
      <p>Da wir im geschäftlichen Betrieb wissen müssen, mit wem wir es zu tun haben, ist die
        Registrierung nur mit Ihrem richtigen Namen (Klarnamen) möglich und nicht mit
        Pseudonymen.
      </p>
      <h4 class="font-medium text-xl">Registrierung mit Pseudonymen</h4>
      <p>Bei der Registrierung können Pseudonyme verwendet werden, das heißt Sie müssen sich
        bei uns nicht mit Ihrem richtigen Namen registrieren. Damit ist sichergestellt, dass Ihr
        Name nicht von uns verarbeitet werden kann.
      </p>
      <h4 class="font-medium text-xl">Speicherung der IP-Adresse</h4>
      <p>Im Zuge der Registrierung, Anmeldung und Kontonutzung speichern wir aus
        Sicherheitsgründen die IP-Adresse im Hintergrund, um die rechtmäßige Nutzung
        feststellen zu können.
      </p>
      <h4 class="font-medium text-xl">Öffentliche Profil</h4>
      <p>Die Nutzerprofile sind öffentlich sichtbar, d.h. man kann Teile des Profils auch ohne
        Angabe von Benutzername und Passwort im Internet sehen.
      </p>
      <h4 class="font-medium text-xl">2-Faktor-Authentifizierung (2FA)</h4>
      <p>Eine Zwei-Faktor-Authentifizierung (2FA) bietet zusätzlich Sicherheit bei der Anmeldung,
        da sie verhindert, dass man sich z.B. ohne Smartphone anmeldet. Diese technische
        Maßnahme zur Absicherung Ihres Kontos schützt Sie also vor dem Verlust von Daten oder
        unzulässigen Zugriffen auch wenn Benutzername und Passwort bekannt wären. Welches
        2FA zum Einsatz kommt, erfahren Sie bei der Registrierung, Anmeldung und im Konto
        selbst.
      </p>
    </div>
    <h3 class="text-2xl pt-5 relative group w-fit">
      Zahlungsanbieter Einleitung
      <span class="absolute inset-x-0 bottom-1  h-2 w-full rounded-lg z-[-1]"/>
    </h3>
    <div class="space-y-4 pl-5">
      <h4 class="font-medium text-xl">Was ist ein Zahlungsanbieter?</h4>
      <p>Wir verwenden auf unserer Website Online-Zahlungssysteme, die uns und Ihnen ein
        sicheres und reibungsloses Bezahlverfahren ermöglichen. Dabei können unter anderem
        auch personenbezogene Daten an den jeweiligen Zahlungsanbieter gesendet, gespeichert
        und dort verarbeitet werden. Bei Zahlungsanbietern handelt es sich um Online-Zahlungssysteme, die es Ihnen ermöglichen eine Bestellung über Online-Banking
        durchzuführen. Dabei wird die Zahlungsabwicklung durch den von Ihnen gewählten
        Zahlungsanbieter durchgeführt. Wir erhalten anschließend eine Information über die
        getätigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Es gibt kaum noch Banken, die solche Zahlungsmethoden
        nicht anbieten bzw. akzeptieren.</p>
      <h4 class="font-medium text-xl">Warum verwenden wir Zahlungsanbieter auf unserer Website?</h4>
      <p>Wir wollen natürlich mit unserer Website und unserem eingebundenen Onlineshop den
        bestmögliche Service bieten, damit Sie sich auf unserer Seite wohl fühlen und unsere
        Angebote nutzen. Wir wissen, dass Ihre Zeit kostbar ist und speziell
        Zahlungsabwicklungen schnell und reibungslos funktionieren müssen. Aus diesen
        Gründen bieten wir Ihnen diverse Zahlungsanbieter an. Sie können Ihren bevorzugten
        Zahlungsanbieter wählen und so in gewohnter Manier bezahlen.</p>
      <h4 class="font-medium text-xl">Welche Daten werden verarbeitet?</h4>
      <p>Welche Daten genau verarbeitet werden, hängt natürlich von dem jeweiligen
        Zahlungsanbieter ab. Doch grundsätzlich werden Daten wie Name, Adresse, Bankdaten
        (Kontonummer, Kreditkartennummer, Passwörter, TANs usw.) gespeichert. Dabei handelt
        es sich um notwendige Daten, um überhaupt eine Transaktion durchführen zu können.
        Zudem können auch etwaige Vertragsdaten und Userdaten, wie zum Beispiel wann Sie
        unsere Website besuchen, für welche Inhalte Sie sich interessieren oder welche
        Unterseiten Sie anklicken, gespeichert werden. Auch Ihre IP-Adresse und Informationen
        zu Ihrem verwendeten Computer werden von den meisten Zahlungsanbietern gespeichert.</p>
      <p>
        Die Daten werden in der Regel auf den Servern der Zahlungsanbietern gespeichert und
        verarbeitet. Wir als Websitebetreiber erhalten diese Daten nicht. Wir werden nur darüber
        informiert, ob die Zahlung funktioniert hat oder nicht. Für Identitäts- und Bonitätsprüfungen
        kann es vorkommen, dass Zahlungsanbieter Daten an die entsprechende Stelle
        weiterleiten. Für alle Zahlungsgeschäfte gelten immer die Geschäfts- und
        Datenschutzgrundlagen des jeweiligen Anbieters. Schauen Sie sich daher bitte immer
        auch die Allgemeinen Geschäftsbedingen und die Datenschutzerklärung des
        Zahlungsanbieters an. Sie haben auch jederzeit das Recht beispielsweise Daten löschen
        oder korrigieren zu lassen. Bitte setzen Sie sich bezüglich Ihrer Rechte (Widerrufsrecht,
        Auskunftsrecht und Betroffenheitsrecht) mit dem jeweiligen Dienstanbieter in Verbindung.
      </p>
      <h4 class="font-medium text-xl">Dauer der Datenverarbeitung</h4>
      <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten sofern wir weitere
        Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so
        lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
        notwendig ist. Wenn es wie zum Beispiel im Fall von Buchhaltung gesetzlich
        vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden. So bewahren
        wir zu einem Vertrag gehörige Buchungsbelege (Rechnungen, Vertragsurkunden,
        Kontoauszüge u.a.) 10 Jahre (§ 147 AO) sowie sonstige relevante Geschäftsunterlagen 6
        Jahre (§ 247 HGB) nach Anfallen auf.</p>
      <h4 class="font-medium text-xl">Widerspruchsrecht</h4>
      <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer
        personenbezogenen Daten. Bei Fragen können Sie auch jederzeit Verantwortliche des
        verwendeten Zahlungsanbieter kontaktieren. Kontaktdaten finden Sie entweder in unserer
        spezifischen Datenschutzerklärung oder auf der Website des entsprechenden
        Zahlungsanbieters</p>
      <p>
        Cookies, die Zahlungsanbieter für ihre Funktionen verwenden, können Sie in Ihrem
        Browser löschen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie
        verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber,
        dass dann eventuell der Zahlungsvorgang nicht mehr funktioniert.
      </p>
      <h4 class="font-medium text-xl">Rechtsgrundlage</h4>
      <p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <b>(Art. 6
        Abs. 1 lit. b DSGVO)</b> neben den herkömmlichen Bank-/Kreditinstitutionen auch andere
        Zahlungsdienstleister an. In den Datenschutzerklärungen der einzelnen Zahlungsanbietern
        (wie zum Beispiel Amazon Payments, Apple Pay oder Discover) wird Ihnen ein genauer
        Überblick über die Datenverarbeitung und Datenspeicherung geboten. Zudem können Sie
        sich bei Fragen zu datenschutzrelevanten Themen stets an die Verantwortlichen richten.</p>
      <p>Informationen zu den speziellen Zahlungsanbietern erfahren Sie – sofern vorhanden – in
        den folgenden Abschnitten.</p>
      <h4 class="font-medium text-xl">Mollie Datenschutzerklärung</h4>
      <p>Wir verwenden für unserer Website Mollie, ein Dienst für Online-Zahlungsverfahren.
        Dienstanbieter ist das niederländische Unternehmen Mollie B.V. Keizersgracht 126,
        1015CW Amsterdam, Niederlande.</p>
      <p>
        Mehr über die Daten, die durch die Verwendung von Mollie verarbeitet werden, erfahren
        Sie in der Datenschutzerklärung auf <a href="https://www.mollie.com/at/privacy">https://www.mollie.com/at/privacy</a>.
      </p>
      <p>Alle Texte sind urheberrechtlich geschützt.</p>
      <p>Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/">Datenschutz Generator</a>  von AdSimple</p>

    </div>
  </div>
</template>

<script>
import {userStore} from "@/store/userStore"

export default {
  name: "datasecure",
  title: 'Datenschutz',
  components: {},
  setup() {
    const user = userStore()
    return {user}
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>
