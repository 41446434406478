<template>
  <main class="!p-0">
    <div class="relative">
      <div class="relative w-full h-[500px] bg-center bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/loungesOverviewBackground.jpg')">
        <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
          <!-- Ihr Inhalt hier -->
        </div>
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h3 class="text-center text-4xl md:text-4xl text-tertiary font-bold">FAQ</h3>
      </div>
    </div>
    <div class="px-6 md:max-w-screen-sm md:m-auto pt-12">
      <div class="bg-gray-200 rounded-lg py-6 px-6 text-center cursor-pointer transition">
        <div class="" id="question" @click="toggleLostTicketAnswer">
          <div class="flex justify-center">
            <div class="bg-secondary rounded-full w-16 h-16 p-4">
              <img src="/assets/images/icons/ticket-icon.svg" alt="" class="w-full">
            </div>
          </div>
          <h5 class=" text-xl pt-6">Ich habe kein Ticket erhalten</h5>
        </div>
        <div v-if="showLostTicketAnswer" class="text-left pt-4">
          <h3 class="text-2xl pb-2">Ich habe kein Ticket erhalten.<br>Was kann ich tun?</h3>
          <p class="text-gray-600">Solltest du dein Ticket nach der Bestellung nicht erhalten haben, kann dies verschiedene Gründe haben.</p>

          <h3 class="text-xl pb-2 pt-6">Check deinen Spam Ordner</h3>
          <p class="text-gray-600">Ist unsere Mail fälschlicher Weise in deinen Spam Ordner gerutscht?</p>

          <h3 class="text-xl pb-2 pt-6">Deine Zahlung wurde abgebrochen oder konnte nicht abgebucht werden</h3>
          <p class="text-gray-600">Prüfe, ob der Betrag von deinem Konto wirklich abgebucht wurde.</p>

          <h3 class="text-xl pb-2 pt-6">Du hast die falsche E-Mail Adresse angegeben</h3>
          <p class="text-gray-600">Das kann durchaus passieren! Leider kann dir dabei nur unser Support helfen.</p>

          <h3 class="text-2xl pb-2 pt-6">Hat nichts geholfen?<br>Dann hilft dir unser Support.</h3>
          <router-link to="lostticket" class="">
            <button class="mt-4 w-full md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:primary text-md hover:text-primary transition">
              Ticket erneut zusenden / Runterladen
            </button>
          </router-link>
          <div class="p-4"></div>
          <p class="text-gray-600">Du erreichst uns telefonisch unter</p>
          <p class="text-purple-300"><a href="tel:+40 101 101 101 101">+49 651 9985661</a></p>
          <div class="p-4"></div>
          <p class="text-gray-600">oder per Mail unter</p>
          <p class="text-purple-300"><a href="mailto:mail@mamamachtparty.de">mail@mamamachtparty.de</a></p>
          <div class="p-4"></div>
          <h5 class="text-lg ">Bitte gib uns folgende Informationen durch:</h5>
          <ul class="list-disc px-4 pt-2">
            <li class="text-gray-600">Link zur Eventseite der gekauften Tickets</li>
            <li class="text-gray-600">Name & Datum der Veranstaltung</li>
            <li class="text-gray-600">Angegebene E-Mail-Adresse</li>
            <li class="text-gray-600">Angegebenen Vor-und Nachnamen</li>
          </ul>
          <div class="p-4"></div>
          <button @click="toggleLostTicketAnswer" class="mt-4 w-full md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:border-primary text-md hover:text-primary transition">
            Info schließen
          </button>
        </div>
      </div>
      <div class="p-6"></div>
      <div v-for="(question, index) in faqList" :key="index" class="bg-gray-950 rounded-lg mt-4">
        <div @click="toggleFAQ(index)" :class="[question.open ? 'bg-secondary' : 'bg-gray-950', 'text-white uppercase font-semibold p-6 rounded-t-lg cursor-pointer hover:bg-secondary transition']">
          <h5>{{question.question}}</h5>
        </div>
        <div class="p-6 !pt-6 bg-gray-200" v-if="question.open">
          <p class="text-gray-600">{{question.answer}}</p>
          <div v-show="fundgrubeButton" class="p-4 text-center">
            <router-link to="/">
              <button class="w-[250px] md:w-[300px] py-4 border-2 md:border-4 border-solid rounded-full hover:botext-primary text-md hover:text-primary transition">
                Fundgrube öffnen
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center py-6">
        <h5 class="text-lg">Du hast weitere Frage?</h5>
        <h5 class="text-lg"><a href="mailto:secretclubtrier@gmail.com" class="text-secondary">mail@mamamachtparty.de</a> hilft dir weiter!</h5>
      </div>
    </div>
    <div class="md:p-12"></div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";
import {aSyncData, stringToDate} from "@/modules/main";
import LoungesView from "@/components/lounge/lounges";

export default {
  name: "FaqView",
  props: ["lounge"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      showLostTicketAnswer: false,
      faqList: [
        {
          question:'Wie lange dauert es bis ich das Ticket erhalte?',
          answer:'Das MamaMachtParty System schickt sofort deine Tickets raus. Schau bitte in deinem E-Mail Postfach auch im Spam-Ordner nach. Sollte 15 Minuten nach Bestellung noch kein Ticket bei dir eingegangen sein, so schreib uns bitte eine E-Mail an: mail@mamamachtparty.de unter Angabe deiner Bestellnr., deiner angegebenen E-Mail und deinem Namen.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'AUF DEM TICKET STEHT MEIN NAME, ES IST ABER NICHT FÜR MICH?',
          answer:'Das ist kein Problem! Es zählt nur der gültige QR Code.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Muss man online Karten zusätzlich aktivieren?',
          answer:'Nein, die Online Karten müssen nicht aktiviert werden.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'MUSS MAN ONLINE KARTEN AUSDRUCKEN ODER REICHEN SIE AUF DEM HANDY?',
          answer:'Die Online Karte kannst du ganz bequem per Smartphone am Einlass vorzeigen.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Sind Online Karten übertragbar?',
          answer:'Onlinekarten sind in den meisten Fällen unpersonalisiert, das heißt, dass diese übertragbar sind.',
          open: false,
          fundgrubeButton: false,
        },
        {
          question:'Ich habe meine Jacke im Club vergessen, was kann ich tun?',
          answer:'Melde dich dafür in unserer App an und nutze die Fundgrube um deinen Verlust zu melden.',
          open: false,
          fundgrubeButton: true,
        },
        // {
        //   question:'Ich habe mein Geldbeutel, Handy, Tasche, etc verloren, was kann ich tun?',
        //   answer:'Melde dich dafür in unserer App an und nutze die Fundgrube um deinen Verlust zu melden.',
        //   open: false,
        //   fundgrubeButton: true,
        // },
      ]
    }
  },
  methods: {
    toggleLostTicketAnswer() {
      this.showLostTicketAnswer = !this.showLostTicketAnswer;
    },
    toggleFAQ(index) {
      this.faqList[index].open = !this.faqList[index].open;
    }

  }
}
</script>