<template>
  <div class="flex justify-center flex-wrap gap-4">
    <p v-if="!mediaPreviews.length" class="col-span-full text-center text-lg pb-5">Noch keine Alben vorhanden😕</p>
    <div v-for="(media, index) in mediaPreviews" :key="index">
      <router-link to="/media">
        <div class="rounded-t-lg">
          <div class="px-4 py-6 gap-x-3">
            <div class="col-sp-3 bg-secondary rounded-t-lg text-center pt-3 p-3">
              <h6 class="text-white text-sm">{{ media.date }}</h6>
              <h5 class="text-2xl font-semibold text-white">{{ media.title }}</h5>
              <h6 class="text-white text-sm">{{ media.description }}</h6>
            </div>
            <div class="">
              <img class="rounded-b-lg" :src="`${config.projectURL}assets/images/media/${media.cover}_thumbnail.jpg`">
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "mediaPreview",

  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      mediaPreviews: []
    }
  },
  mounted() {
    this.getAlbums()
  },
  methods:{
    getAlbums (){
      aSyncData('home/get3albums', {})
          .then(r => {
            if(r.status === 250){
              this.mediaPreviews = r.data
              console.log(this.mediaPreviews)
            }
          })
          .catch(e => {})
    },
  }
}
</script>