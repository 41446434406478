<template>
  <main class="!p-0">

    <div class="relative">
      <div class="relative w-full h-[400px] md:h-[500px] bg-bottom bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/eventOverviewBackground.jpg')">
        <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
          <!-- Ihr Inhalt hier -->
        </div>
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h3 class="text-center text-4xl md:text-4xl text-tertiary font-bold">Events & Tickets</h3>
      </div>
    </div>
    <div class="px-6 md:max-w-screen-xl md:m-auto md:pb-24">
      <div v-for="(mtlEvents,month) in months" :key="month" class="mt-16">
<!--        Hier den Monat noch Hübsch machen-->
        <div class="relative group pb-2">
          <h2 class="relative text-secondary text-3xl md:text-4xl text-center font-semibold z-[10] inline-block">
            <span class="absolute left-0 bottom-1 bg-tertiary/60 h-2 w-full rounded-lg z-[-1]"></span>
            {{ Monate[parseInt(month.slice(5,7))-1] }}
          </h2>
          - {{ month.slice(0,4) }}
        </div>


        <div class="md:grid md:grid-cols-2 gap-x-8">
          <events v-for="ev in mtlEvents" :event="ev" :key="ev.id" />
        </div>
      </div>
      <div class="p-6 md:p-0"></div>

    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import events from "@/components/home/event";
import {notificationStore} from "@/store/notificationStore";
import {aSyncData, stringToDate} from "@/modules/main";

export default {
  name: "EventsOverview",
  components: {'events': events,},
  props: ["event"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      city: null,
      cityName: null,
      events: [],
      nearestEvents: [],
      page: 'start',
      cities: [],
      searchString: '',
      filterModal: false,
      filterItems: '',
      cityItems: '',
      selectedFilters: [],
      filterDate: false,
      Monate:['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
      months:{},

    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
    this.getEvents()
  },
  methods: {
    getEvents(){
      const _this = this
      aSyncData('home/getEvents')
          .then(r => {
            if(r.status === 250){
              _this.events = r.data
              this.sortedMonths()
            }
          })
          .catch(e => {})
    },
    sortEventsByMonth() {
      return this.events.sort((a, b) => a.startTime - b.startTime);
    },
    sortedMonths() {
      const sortedEvents = this.sortEventsByMonth();
      this.months = {}; // Zurücksetzen des `months`-Objekts
      for (const event of sortedEvents) {
        const monthKey = new Date(event.startTime).toISOString().slice(0, 7);
        if (!this.months[monthKey]) {
          this.months[monthKey] = [];
        }
        this.months[monthKey].push(event);
      }
      console.log(this.months)
    },
  }

}
</script>