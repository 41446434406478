<template>
  <div>
    <main class="content-500 pad-16 mt-12">
      <div>
        <section class="br-10 pad-24 bg-gray-100" style="margin-top: 80px;">
          <div style="margin-bottom: 12px;">
            <span class="bg-secondary text-white pad-8 br-10" style="font-size: 0.8em;">Selfservice</span>
          </div>
          <h2 class="f-normal text-gray-800" style="font-size: 1.6em;">
            Du hast dein Ticket nicht erhalten?
          </h2>
          <h3 class="f-light !text-gray-600" style="color: #ffffff66; font-size: 1.1em; margin-top: 8px;">
            Fülle das folgende Formular mit den gleichen Daten wie beim Ticketkauf aus.
            Anschließend kannst du dir die Tickets erneut zusenden lassen, oder direkt
            herunterladen.
          </h3>
        </section>
        <section v-if="!searchTickets" style="margin-top: 16px;">
          <form class="pad-8 !text-gray-800" @submit.prevent="getTickets()" method="post">
            <div class="field">
              <label style="padding-bottom: 6px;" class="!text-gray-800">Event auswählen</label>
              <select v-model="event" required>
                <option v-for="event in events" :key="event.id" :value="event.id">
                  {{ event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] + ' - ' + event.title }}
                </option>
              </select>
            </div>
            <div class="field">
              <label for="firstname" class="!text-gray-800">Vorname</label>
              <input type="text" v-model="firstname" name="firstname" id="firstname" placeholder="Vorname" required>
            </div>
            <div class="field">
              <label for="lastname" class="!text-gray-800">Nachname</label>
              <input type="text" v-model="lastname" name="lastname" id="lastname" placeholder="Nachname" required>
            </div>
            <div class="field">
              <label for="email" class="!text-gray-800">E-Mail-Adresse</label>
              <input type="email" v-model="email" name="email" id="email" placeholder="E-Mail-Adresse" required>
            </div>
            <button class="button bg-secondary color-white fluid" >
              Tickets abrufen
            </button>
          </form>
        </section>
        <section v-else style="margin-top: 16px;">
          <h3 class="t-center f-bold color-black" style="font-size: 1.4em;">Deine Tickets</h3>
          <article v-for="titems in tickets" :key="titems.id" class="color-gray-900 bg-gray-100 br-10 pad-16" style="margin-top: 16px;">
              <h4 class="f-bold">{{titems.title}}</h4>
              <h5>{{titems.ticketTitle}}</h5>
              <div class="pad-4"></div>
              <button @click="download(titems.id)" type="button" class="button bg-secondary color-white fluid" style="margin-bottom: 8px;">
                Ticket herunterladen
              </button>
              <button @click="resendEmail(titems.id)" type="button" class="button bg-secondary color-white fluid">
                Ticket erneut versenden
              </button>
              <p v-if="sendTicket" class="color-grey t-center pad-8">Bitte überprüfe dein E-Mail-Postfach</p>
          </article>
          <article v-if="tickets.length === 0" class="color-white t-center bg-gray-100 br-10 pad-16" style="margin-top: 16px;">
            <p style="font-size: 0.8em;" class="text-gray-800">
              Leider konnten wir keine Tickets zu deinen Angaben finden. <br>Bitte überprüfe deine Angaben und versuche es erneut.<br>
              Alternativ kannst du dich gerne an unseren Support werden: <a class="!text-secondary" href="mailto:mail@mamamachtparty.de">mail@mamamachtparty.de</a>
            </p>
            <div class="pad-8"></div>
            <button @click="searchTickets = false" type="button" class="button bg-primary color-white fluid">
              Eingaben überprüfen
            </button>
          </article>
        </section>
      </div>
      <div class="pad-40"></div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "LostTicket",
  title: "Ticket nicht erhalten?",
  components: {},
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      searchTickets: false,
      event: '',
      firstname: '',
      lastname: '',
      email: '',
      events: [],
      tickets: [],
      sendTicket: false

    }
  },
  mounted() {
    this.config.loading = false
    this.loadEvents()
  },
  methods:{
    loadEvents(){
      const _this = this
      this.axios.post('lostticket/getEvents')
          .then((response) => {
            if(response.status === 250){
              _this.events = response.data;
            }
          })
    },

    resendEmail(ticketId){
      this.loading = true
      aSyncData('lostticket/resendEmail', {ticketId: ticketId})
          .then(r => {console.log(r)})
          .catch(e => {})
          .finally(() => {this.loading = false; this.sendTicket = true;})

    },
    download(ticketId){
      this.loading = true
      aSyncData('lostticket/download', {ticketId: ticketId}, {
        responseType: 'blob',
      })
          .then(r => {
            if(r.status === 250){
              const link = document.createElement('a')
              link.href = URL.createObjectURL(r.data)
              link.setAttribute('download', ticketId + '-ticket.pdf')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(link.href)
            }
          })
          .catch(e => {})
          .finally(() => {this.loading = false})
    },

    getTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('event', this.event)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('email', this.email)
      this.axios.post(this.config.projectURL + 'lostticket/getLostTickets', fD)
          .then(response => {
            _this.searchTickets = true
            if(response.status === 250){
              _this.tickets = response.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
  }
}
</script>

<style scoped>

.field{
  margin-bottom: 12px;
}
.field label{
  color: #ffffff;
}
.field input,
.field select{
  padding: 12px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #00000010;
  border-radius: 8px;
  border: none;
}
</style>