<template>
  <div>
    <main class="p-0">
      <section>
        <div :class="{'min-h-full relative isolate' : !isIOS, } ">
          <div class="absolute bottom-8 w-full">
            <a href="#welcomeHeader">
            <svg class="h-20 mx-auto animate-bounce" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m6 9 6 6 6-6"></path></svg>
            </a>
          </div>
<!--          Desktop Geräte-->
          <div class="hidden md:block h-[660px] max-w-screen-xl m-auto mt-32 px-6">
            <swiper
                :spaceBetween="30"
                :centeredSlides="true"
                :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
                }"
                :pagination="{
                clickable: false,
                }"
                :navigation="false"
                :modules="modules"
                class="mySwiper rounded-xl"
            >
              <swiper-slide>
                <img src="/assets/images/home/slider/LIV-117.jpg" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="/assets/images/home/slider/LIV-74.jpg" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="/assets/images/home/slider/LIV-62.jpg" alt="" />
              </swiper-slide>
              <swiper-slide>
                <img src="/assets/images/home/slider/Nachtschicht021023NeonNachtaktiv_.jpg" alt="" />
              </swiper-slide>
            </swiper>
          </div>

          <video v-if="!isIOS" src="/assets/videos/video9_16.mp4" class="min-w-full min-h-full h-screen object-cover md:hidden -z-[1]" autoplay loop muted :controls="false">
          </video>
<!--      IOS Geräte-->
          <div v-else class="text-center px-6">
            <div class="mt-40">
              <swiper
                  :spaceBetween="30"
                  :centeredSlides="true"
                  :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
                }"
                  :pagination="{
                clickable: false,
                }"
                  :navigation="false"
                  :modules="modules"
                  class="mySwiper rounded-xl"
              >
                <swiper-slide>
                  <img src="/assets/images/home/slider/LIV-117.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="/assets/images/home/slider/LIV-74.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="/assets/images/home/slider/LIV-62.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="/assets/images/home/slider/Nachtschicht021023NeonNachtaktiv_.jpg" alt="" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="mt-6">
              <h4 class="text-lg font-medium tt-40 text-secondary">ℹ️ WAS IST </h4>
              <h2 class="relative text-secondary text-4xl md:text-5xl font-semibold md:text-4xl text-center z-[10] inline-block">
                MAMA MACHT PARTY?
              </h2>
              <p class="pt-5 w-full text-lg leading-8">Früher haben wir nächtelang durchgefeiert, doch dies ist im aktuellen Alltag mit Kindern fast unmöglich. Wenn wir dann mal Zeit und Freundinnen finden, um rauszugehen, laufen unsere Lieblingssongs nur ganz selten im Club, und bis in die Morgenstunden zu feiern ist schwierig. Daher haben wir uns entschlossen, eine Party nur für Mamas zu machen, mit mama-freundlichen Zeiten, sodass wir feiern können und danach unsere Kinder ins Bett bringen können. So entstand „Mama macht Party“.</p>
            </div>
          </div>
        </div>
      </section>
<!--      mobile-->
      <section id="welcomeHeader" v-if="!isIOS" v-observe-visibility="visibilitySection1Visible"   :class="[section1Visible?'animate__fadeInUp':'','animate__animated md:max-w-screen-xl md:m-auto relative text-center pt-16 md:pt-24 px-6']">
        <div class="relative z-[2]">
          <h4 class="text-md md:pb-1">ℹ️ WAS IST </h4>
          <h2 class="relative text-secondary text-4xl md:text-5xl font-semibold md:text-4xl text-center z-[10] inline-block leading-[50px]">
            „MAMA MACHT PARTY“?
          </h2>
          <p class="pt-5 w-full leading-8 text-lg">Früher haben wir nächtelang durchgefeiert, doch dies ist im aktuellen Alltag mit Kindern fast unmöglich. Wenn wir dann mal Zeit und Freundinnen finden, um rauszugehen, laufen unsere Lieblingssongs nur ganz selten im Club, und bis in die Morgenstunden zu feiern ist schwierig. Daher haben wir uns entschlossen, eine Party nur für Mamas zu machen, mit mama-freundlichen Zeiten, sodass wir feiern können und danach unsere Kinder ins Bett bringen können. So entstand „Mama macht Party“.</p>
        </div>
      </section>

      <section id="searchResults" v-observe-visibility="visibilitySection2Visible"   :class="[section2Visible?'animate__fadeInUp':'','animate__animated  md:max-w-screen-xl md:m-auto relative isolate']">
<!--        Banner Klecks-->
        <div class="absolute inset-x-0 -left-40 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div class="relative left-[calc(70%-1rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#f1fc00] to-[#ffffff] opacity-60 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
        </div>
        <div class="absolute inset-x-0 top-[calc(20%)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(70%-30rem)]" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#f1fc00] to-[#ffffff] opacity-50 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
        </div>
        <div class="">
          <h3 class="text-4xl text-center text-secondary pt-12 md:pt-24 pb-12 md:font-bold">Events & Tickets</h3>
          <div class="md:grid md:grid-cols-3 gap-x-4">
            <events v-for="event in eventsFiltered" :event="event" :key="event.id" />
          </div>
          <p v-if="!eventsFiltered.length && cityName != null" class="t-center">
            Leider konnten wir keine passenden Events in  <span class="color-blue">{{ cityName }}</span> und deinen Filtern  finden
          </p>
        </div>
      </section>

      <section v-if="cityName != null" id="searchResultsHeader" class="pad-16">
        <hr>
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h2 style="font-size: 1.2em; padding-top: 8px;" class="f-normal">
              Events in der Nähe von <span class="f-bold color-blue">{{ cityName }}</span>
            </h2>
          </div>
        </div>
      </section>

      <section class="px-6">
        <events v-for="event in nearestEventsFiltered" :event="event" :key="event.id" />
        <p v-if="eventsFiltered.length && !nearestEventsFiltered.length && city != null" class="t-center">
          Leider konnten wir keine passenden Events in der Nähe von <span class="color-blue">{{ cityName }}</span> und deinen Filtern finden
        </p>
        <div class="text-center md:mt-16 relative z-10">
          <router-link to="/events" class="w-full md:w-[300px] py-4 px-4 mx-auto border-2 md:border-4 border-solid rounded-full hover:botext-secondary text-md hover:text-secondary transition">
            ALLE EVENTS & TICKETS
          </router-link>
        </div>
      </section>

      <div class="p-8 md:p-24"></div>

      <section v-if="!config.cordova"  v-observe-visibility="visibilitySection3Visible"   :class="[section3Visible?'animate__fadeInUp':'', 'animate__animated px-6 md:max-w-screen-xl md:m-auto relative isolate pt-16']">
        <h3 class="text-2xl md:text-4xl">Keine News mehr verpassen,<br><span class="text-secondary"> hol dir unsere APP!?</span></h3>
        <div class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#f1fc00] to-[#ffffff] opacity-50 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
        </div>
        <div class="mt-6 md:grid md:grid-cols-2 ">
          <div class="hidden md:block">
            <ul class="grid grid-cols-3">
              <li v-for="item in appNews" :key="item" class="flex gap-x-2 items-center">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="rounded-fultext-primary md:w-8">
                {{ item }}</li>
            </ul>
            <div class="grid grid-cols-4 mt-6 gap-x-4">
              <div class="col-sp-1">
                <img src="/assets/images/icons/google-play-badge.335c666c.png" :alt="`GooglePlay Logo mit einem Link zur ${config.projectName} App`" class="md:w-40">
              </div>
              <div class="col-sp-1">
                <img src="/assets/images/icons/app-store-badge.8f62d0fe.png" :alt="`Apple App Store Logo mit einem Link zur ${config.projectName} App`" class="md:w-40">
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 md:hidden">
            <div class="flex gap-x-3 md:gap-x-4 col-span-6">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">News</h4>
              </div>
            </div>
            <div class="flex gap-x-3 col-span-6 md:col-span-4">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">Events</h4>
              </div>
            </div>
            <div class="flex gap-x-3 col-span-6 md:col-span-4 md:row-span1">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">Tickets</h4>
              </div>
            </div>
            <div class="flex gap-x-3 col-span-6 md:col-span-4">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">Fotos</h4>
              </div>
            </div>
            <div class="flex gap-x-3 col-span-6 md:col-span-4">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">Specials</h4>
              </div>
            </div>
            <div class="flex gap-x-3 col-span-6 md:col-span-4">
              <div class="">
                <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="w-8 rounded-fultext-primary md:w-12">
              </div>
              <div class="">
                <h4 class="text-lg pb-2">Und vieles mehr!</h4>
              </div>
            </div>
            <div class="grid grid-cols-4 mt-4 gap-x-3">
              <div class="col-sp-1">
                <img src="/assets/images/icons/google-play-badge.335c666c.png" :alt="`GooglePlay Logo mit einem Link zur ${config.projectName} App`" class="md:w-40">
              </div>
              <div class="col-sp-1">
                <img src="/assets/images/icons/app-store-badge.8f62d0fe.png" :alt="`Apple App Store Logo mit einem Link zur ${config.projectName} App`" class="md:w-40">
              </div>
            </div>
          </div>
          <div class="">
            <img src="/assets/images/home/mockup-homepage.webp" class="w-60 rotate-3 mt-12 m-auto md:-mt-36 md:w-72" :alt="`Produktbild der ${config.projectName} App die eine Auswahl an kommenden Events in deiner Umgebung anzeigt.`">
          </div>
        </div>

      </section>

      <div class="p-8 md:p-24"></div>

      <section  v-observe-visibility="visibilitySection4Visible"   :class="[section4Visible?'animate__fadeInUp':'','animate__animated bg-gray-100 px-6 pb-16 md:pb-24 pt-12']">
        <div class="md:max-w-screen-xl md:m-auto">
          <h3 class="text-4xl text-center text-secondary pt-12 md:pt-32 pb-12 md:font-bold">Fotos & Media</h3>
          <mediaPreview></mediaPreview>
          <div class="text-center md:mt-16">
            <router-link to="/media" class="w-full md:w-[300px] py-4 px-4 border-2 md:border-4 border-solid rounded-full hover:botext-secondary text-md hover:text-secondary transition">
              Gallerie öffnen
            </router-link>
          </div>
        </div>
      </section>

      <div class="p-8 md:p-16 bg-gray-100"></div>

<!--      <section v-observe-visibility="visibilitySection5Visible"   :class="[section5Visible?'animate__fadeInUp':'','md:max-w-screen-xl md:m-auto relative isolate']">-->
<!--        <div class="absolute inset-x-0 top-40 -z-10 -left-[50%] sm:-left-[100%] sm:-top-20 rotate-180 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">-->
<!--          <div class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#7f04e5] to-[#000000] opacity-40" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />-->
<!--        </div>-->
<!--        <div class="z-20">-->

<!--          <h3 class="px-6 text-4xl text-center text-primary pt-12 pb-12">Lounges</h3>-->
<!--          <div class="mt-6 md:grid md:grid-cols-3 md:gap-x-12 z-20">-->
<!--            <div class="px-6 md:col-span-2 md:mt-20">-->
<!--              <h5 class="text-xl pb-8">Deine Vorteile der Lounge:</h5>-->
<!--              <ul class="grid">-->
<!--                <li v-for="item in lounges" :key="item" class="flex gap-x-2 items-center">-->
<!--                  <img src="/assets/images/icons/done_FILL0_wght400_GRAD0_opsz24.svg" class="rounded-fultext-primary w-8">-->
<!--                  {{ item }}-->
<!--                </li>-->
<!--              </ul>-->
<!--              <p class="pt-4">Die Reservierung der VIP Lounge ist übrigens komplett kostenlos, jedoch mit einem Mindestverzehr verbunden.</p>-->
<!--              <div class="mt-6 md:flex md:gap-x-2 ">-->
<!--                <router-link to="/lounges" class="cursor-pointer flex items-center justify-center gap-x-1.5 uppercase w-4/5 md:w-[280px] py-3 z-20 border-2 border-solid rounded-full hover:botext-primary text-md hover:text-primary transition">-->
<!--                  <img src="/assets/images/icons/CreditCard.svg" class="w-7">-->
<!--                  Jetzt reservieren-->
<!--                </router-link>-->
<!--&lt;!&ndash;                <div class="p-2 md:p-0"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <button class="flex items-center justify-center gap-x-1.5 uppercase w-4/5 md:w-[280px] py-3 z-20 border-2 border-solid rounded-full hover:border-primary text-md hover:text-primary transition">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="/assets/images/icons/champagne-glasses.svg" class="w-8">&ndash;&gt;-->
<!--&lt;!&ndash;                  Getränkekarte&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="p-6 md:p-0 md:hidden"></div>-->
<!--            <div class="md:col-span-1 relative md:block hidden">-->
<!--              <div class="absolute z-20">-->
<!--                <img src="/assets/images/home/lounge-drinks.jpg" alt="" class="rotate-6 rounded-lg w-72 ">-->
<!--              </div>-->
<!--              <div class="absolute z-10" style="left:32px">-->
<!--                <img src="/assets/images/home/lounge2.jpg" alt="" class="rotate-6 rounded-lg w-80">-->
<!--              </div>-->
<!--              <div class="absolute" style="left:103px">-->
<!--                <img src="/assets/images/home/lounge1.jpg" alt="" class="rotate-6 rounded-lg w-72 ">-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Für Nicht-iOS-Geräte &ndash;&gt;-->
<!--            <div v-if="!isIOS" class="relative h-[400px] md:hidden">-->
<!--              <div class="absolute w-full h-full inset-0 bg-fixed bg-center bg-cover" style="background-image: url('/assets/images/home/lounge-drinks.jpg'); z-index: 10;"></div>-->
<!--            </div>-->

<!--            &lt;!&ndash; Für iOS-Geräte &ndash;&gt;-->
<!--            <div v-else class="relative h-[400px] md:hidden" style="background-image: url('/assets/images/home/lounge-drinks.jpg'); background-size: cover; background-position: center; background-attachment: scroll;">-->
<!--              &lt;!&ndash; Ihr Inhalt hier &ndash;&gt;-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </section>-->
    </main>
    <transition name="modal" id="filterModal">
      <div class="vueModal" v-if="filterModal">
        <div class="vueModalOuter" @click="filterModal = false;" style="position: relative;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2 class="color-blue f-bold">
              Eventsfilter
            </h2>
            <div>
              <filterItems v-for="filter in filterItems" :filter="filter" :key="filter.id" :selected-filters="selectedFilters" @select-filter="selectFilter" />
            </div>
            <div class="pad-4" />
            <div class="field t-left">
              <label class="color-white f-bold">Datum</label>
              <input type="date" class="pad-12 bgcolor-black color-white br-10" style="border: none; width: 100%; box-sizing: border-box;" v-model="filterDate">
            </div>
            <div class="pad-8" />
            <button @click="selectedFilters = []; filterDate = false" class="button fluid bgcolor-black color-white" style="margin-right: 8px;">
              Filter zurücksetzen
            </button>
            <div class="pad-4" />
            <button @click="filterModal = false;" class="button fluid bgcolor-blue color-white">
              Events Filtern
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import events from "@/components/home/event";
import filterItems from "@/components/home/filter";
import cityItems from "@/components/home/myCities";
import mediaPreview from "@/components/home/mediaPreview";
import 'animate.css'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import SimpleTypeahead from 'vue3-simple-typeahead'
import {aSyncData, stringToDate} from "@/modules/main";
import {notificationStore} from "@/store/notificationStore";
import MediaPreview from "@/components/home/mediaPreview";

SwiperCore.use([Autoplay]);

export default {
  name: "Home",
  title: "Home",
  components: {
    Swiper,
    SwiperSlide,
    Pagination,
    MediaPreview,
    'events': events,
    'filterItems': filterItems,
    'cityItems': cityItems,
    SimpleTypeahead
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      city: null,
      cityName: null,
      events: [],
      nearestEvents: [],
      page: 'start',
      cities: [],
      searchString: '',
      filterModal: false,
      filterItems: '',
      cityItems: '',
      selectedFilters: [],
      filterDate: false,
      isIOS:false,
      section1Visible:false,
      section2Visible:false,
      section3Visible:false,
      section4Visible:false,
      section5Visible:false,
      appNews: [
        'News',
        'Events',
        'Tickets',
        'Fotos',
        'Specials',
        'Fundgrube',
        'Uvm.'
      ],
      lounges: [
          'kein Schlange stehen und schneller Einlass beim Türsteher',
          'Dekomöglichkeiten bei Geburtstagen etc.',
          'Exklusiver Service',
      ]
    }
  },
  computed: {
    eventsFiltered(){
      const _this = this
      let events = this.events

      if(_this.selectedFilters.length){
        events = events.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      return events
    },
    nearestEventsFiltered(){
      const _this = this
      let nearestEvents = this.nearestEvents

      if(_this.selectedFilters.length){
        nearestEvents = nearestEvents.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      if(_this.filterDate){
        const time = stringToDate(_this.filterDate).getTime()
        nearestEvents = nearestEvents.filter(e => {
          return stringToDate(e.startTime).getTime() <= time && stringToDate(e.endTime).getTime() >= time
        })
      }

      return nearestEvents
    }
  },
  mounted() {
    this.config.loading = false

    this.getFilterItems()
    this.getMyCities()
    this.detectIOS()

    if(this.$route.params.id){
      this.getEventsByCity()
    }else if(this.auth.isAuth && this.user.ipCity?.name){
      this.selectCity(this.user.ipCity)
    }else if(!this.auth.isAuth){
      this.user.getCityByIP()
          .then(r => {
            // Auskommentiert weil zu ungenau
            //this.selectCity(r)
            this.getEvents()
          })
          .catch(e => {
            this.getEvents()
          })
    }else{
      this.getEvents()
    }
  },
  methods: {
    detectIOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Das Gerät verwendet iOS
        this.isIOS = true;
      } else {
        // Das Gerät verwendet kein iOS
        this.isIOS = false;
      }
    },
    getGPS() {
      const _this = this
      this.user.getCityByGeo()
          .then(r => {
            _this.city = r
            _this.cityName = r.name
            _this.selectCity(_this.city)
          })
          .catch(e => {
            console.log(e)
          })
    },
    selectFilter(id){
      if(this.selectedFilters.includes(id)){
        this.selectedFilters.splice(this.selectedFilters.indexOf(id), 1)
      }else{
        this.selectedFilters.push(id)
      }
    },
    getEvents(){
      const _this = this
      aSyncData('home/get3Events')
          .then(r => {
            if(r.status === 250){
              _this.events = r.data
            }
          })
          .catch(e => {})
    },
    getEventsByCity (){
      const _this = this
      aSyncData('home/getCityById', {cityId: this.$route.params.id})
          .then(r => {

            if(r.status === 250){
              _this.city = r.data
              _this.cityName = r.data.name
              this.selectCity(r.data)
            }
          })
          .catch(e => {})
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 3)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.cityItems = response.data
            }
          })
    },
    getFilterItems(){
      const _this = this
      this.axios.post('home/getFilterItems')
          .then((response) => {
            if(response.status === 250){
              _this.filterItems = response.data
            }
          })
    },
    loadCitys(event){
      const _this = this
      this.searchString = event.input
      aSyncData('home/cities', {
            'search': this.searchString
          })
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
      this.cityName = item.name
      const _this = this
      aSyncData('home/getEventsByCity', {
        cityId: item.id
      })
        .then(r => {
          if(r.status === 250){
            _this.events = r.data.events
            _this.nearestEvents = r.data.nearestEvents
          }
        })
    },
    visibilitySection1Visible (isVisible, entry) {
      if (isVisible) this.section1Visible = true
    },
    visibilitySection2Visible (isVisible, entry) {
      if (isVisible) this.section2Visible = true
    },
    visibilitySection3Visible (isVisible, entry) {
      if (isVisible) this.section3Visible = true
    },
    visibilitySection4Visible (isVisible, entry) {
      if (isVisible) this.section4Visible = true
    },
    visibilitySection5Visible (isVisible, entry) {
      if (isVisible) this.section4Visible = true
    }
  }
}
</script>

<style scoped>
video::-webkit-media-controls {
  display:none !important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
main{

}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-weight: 100;
  text-align: center;
  font-size: 1em;
}
section#headerSearch{
  padding: 16px;
  clear: both;
}

section#headerSearch button{
  float: right;
}
section#searchResultsHeader h2{
  font-family: 'Helvetica';
  font-weight: 100;
}

#activeFilter h4{
  display: inline;
  margin-right: 4px;
  font-family: 'Helvetica';
  font-weight: 100;
  color: rgba(255,255,255,0.8);
  font-size: 0.9em;
}
#activeFilter span{
  display: none;
}
#activeFilter span.active{
  display: inline-block;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.8em;
  font-family: 'Helvetica';
  margin-right: 4px;
}
#filterModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #00000095;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 16px;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
}
#filterModal h2{
  font-size: 1.4em;
  margin-bottom: 16px;
}
</style>
<style>
section#headerSearch #city_search_wrapper{
  display: inline;
}
section#headerSearch #city_search{
  display: inline;
  width: calc(100% - 100px);
  border-radius: 8px;
  padding: 12px;
  border: none;
  background-color: var(--second-bg-color);
  color: #ffffff;
  padding-left: 36px;
  background-image: url('/public/assets/images/icons/search-grey.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 8px;
}
.simple-typeahead-list{
  position: absolute;
  width: calc(100% - 80px);
  z-index: 19;
  cursor: pointer;
  background-color: var(--second-bg-color);
}
.simple-typeahead-list-item{
  padding: 12px 16px!important;
  transition: 0.3s;
}
.simple-typeahead-list-item:hover{
  background-color: #000000;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>