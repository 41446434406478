<template>
  <footer @click="supportModal = false;" class="bg-gray-100" >
<!--     mobile Footer-->
    <div class="md:hidden px-6 pt-12 pb-8">
      <router-link to="/"><img src="/assets/images/logo.png" alt="Logo" class="w-44 mb-4"></router-link>
      <ul class="text-white space-y-3 pt-4">
        <li class="font-light text-gray-600"><router-link to="/agb">AGB </router-link></li>
        <li class="font-light text-gray-600"><router-link to="/datenschutz">Datenschutz </router-link></li>
        <li class="font-light text-gray-600"><router-link to="/impressum">Impressum </router-link></li>
      </ul>
      <div class="flex gap-x-2 items-center my-4">
        <div class="flex gap-x-2 items-center my-4">
          <a href="https://www.instagram.com/mama.machtparty/" target="_blank">
            <div class="w-12 h-12 bg-secondary hover:bg-purple-600 transition rounded-full flex justify-center items-center">
              <img src="/assets/images/icons/instagram.svg" alt="Instagram Icon" class="w-auto h-5">
            </div>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556250041249" target="_blank">
            <div class="w-12 h-12 bg-secondary hover:bg-purple-600 transition rounded-full flex justify-center items-center">
              <img src="/assets/images/icons/facebook.svg" alt="Facebook Icon" class="w-auto h-5">
            </div>
          </a>
        </div>
      </div>
      <p>
        SCT Events & Marketing<br>
        Lise Meitner Straße 24<br>
        63457 Hanau
      </p>
    </div>
<!--    // Desktop Footer-->
    <div class="md:grid grid-cols-12 hidden max-w-screen-xl m-auto pt-20 pb-12 px-6 md:gap-x-4">
      <div class="col-sp-3">
        <router-link to="/"><img src="/assets/images/logo.png" alt="Logo" class="w-44 mb-4"></router-link>
        <div class="flex gap-x-2 items-center my-4">
          <a href="https://www.instagram.com/mama.machtparty/" target="_blank">
            <div class="w-12 h-12 bg-secondary hover:bg-purple-600 transition rounded-full flex justify-center items-center">
              <img src="/assets/images/icons/instagram.svg" alt="Instagram Icon" class="w-auto h-5">
            </div>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556250041249" target="_blank">
            <div class="w-12 h-12 bg-secondary hover:bg-purple-600 transition rounded-full flex justify-center items-center">
              <img src="/assets/images/icons/facebook.svg" alt="Facebook Icon" class="w-auto h-5">
            </div>
          </a>
        </div>
        <p class="text-gray-600 text-sm">
          SCT Events & Marketing<br>
          Lise Meitner Straße 24<br>
          63457 Hanau
        </p>
      </div>
      <div class="col-sp-2">
<!--        <h4 class="text-xl pb-4 text-gray-900">Mama macht Party</h4>-->
<!--        <ul class="text-white space-y-3 ">-->
<!--          <li class="text-sm font-light text-gray-600"><router-link to="/events">Events & Tickets </router-link></li>-->
<!--          <li class="text-sm font-light text-gray-600"><router-link to="/galleries">Media & Pics </router-link></li>-->
<!--          <li class="text-sm font-light text-gray-600"><router-link to="/faq">FAQ </router-link></li>-->
<!--        </ul>-->
      </div>
      <div class="col-sp-2">
        <div class="col-sp-2">
          <h4 class="text-xl pb-4 text-gray-900">Events & Tickets</h4>
          <ul class="text-white space-y-3 ">
            <li class="text-sm font-light text-gray-600"><router-link to="/events">Events & Tickets </router-link></li>
            <li class="text-sm font-light text-gray-600"><router-link to="/galleries">Media & Pics </router-link></li>
            <li class="text-sm font-light text-gray-600"><router-link to="/faq">FAQ </router-link></li>
          </ul>
        </div>
      </div>
      <div class="col-sp-2 mr-2 text-gray-900">
        <h4 class="text-xl pb-4">Kommende Events</h4>
        <ul class="text-white space-y-3">
          <li v-for="event in events" :key="event.id" class="text-sm font-light text-gray-600">
            <router-link :to="'/eventDetails/'+event.id">{{event.title}} </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-2">
        <div class="col-sp-2">
          <h4 class="text-xl pb-4 text-gray-900">Rechtliches</h4>
          <ul class="text-white space-y-3 ">
            <li class="text-sm font-light text-gray-600"><router-link to="/agb">AGB </router-link></li>
            <li class="text-sm font-light text-gray-600"><router-link to="/datenschutz">Datenschutz </router-link></li>
            <li class="text-sm font-light text-gray-600"><router-link to="/impressum">Impressum </router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-gray-900">
      <div class=" px-6 py-4 text-center md:flex md:justify-between md:max-w-screen-xl md:m-auto">
        <p class="text-white text-light pb-1">Copyright © 2024 Mama macht Party</p>
      </div>
    </div>
  </footer>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      events:[]
    }
  },
  computed: {
  },
  mounted() {
    this.getEvents()
  },
  methods:{
    getEvents(){
      const _this = this
      aSyncData('home/get5Events')
          .then(r => {
            if(r.status === 250){
              _this.events = r.data
            }
          })
          .catch(e => {})
    },
  }
}
</script>

<style scoped>

</style>